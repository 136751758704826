<template>
  <v-container>
    <v-row class="fill-height">
      <v-col>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                <v-icon class="mr-3">mdi-account</v-icon>
                Perfil
                <v-spacer></v-spacer>
                <v-btn outlined :to="{ name: 'editProfile' }">
                  <v-icon left>mdi-pencil</v-icon>
                  Editar
                </v-btn>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text class="gridSys">
                <v-row>
                  <!-- <v-col md="3">
                    <v-card flat class="rounded-0">
                      <input
                        v-show="false"
                        ref="inputUpload"
                        type="file"
                        v-on:change="previewImage"
                      />
                      <v-btn
                        color="purple"
                        absolute
                        bottom
                        right
                        fab
                        outlined
                        x-small
                        @click="$refs.inputUpload.click()"
                      >
                        <v-icon>mdi-image-edit</v-icon>
                      </v-btn>
                      <v-img v-bind:src="logo">
                        <v-card-title
                          class="align-end text-right fill-height"
                          primary-title
                        >
                          <v-spacer></v-spacer>
                        </v-card-title>
                      </v-img>
                    </v-card>
                  </v-col> -->

                  <v-col>
                    <v-row>
                      <v-col>
                        <div class="mb-2">
                          <strong>
                            Cnpj:
                          </strong>
                          {{ profile.cnpj }}
                        </div>

                        <div class="mb-2">
                          <strong>
                            Nome da empresa:
                          </strong>
                          {{ profile.companyName }}
                        </div>

                        <div class="mb-2">
                          <strong>
                            Nome fantasia:
                          </strong>
                          {{ profile.fantasyName }}
                        </div>

                        <div class="mb-2">
                          <strong>
                            Telefone:
                          </strong>
                          {{ profile.phone }}
                        </div>

                        <div class="mb-2">
                          <strong>
                            Email:
                          </strong>
                          {{ profile.email }}
                        </div>

                        <h4>Endereço.</h4>
                        <div class="mb-2">
                          <strong>
                            Rua:
                          </strong>
                          {{ profile.street }} n° {{ profile.number }}
                        </div>
                        <div class="mb-2">
                          <strong>
                            Complemento:
                          </strong>
                          {{ profile.addressComplement }}
                        </div>
                        <div class="mb-2">
                          {{ profile.district }}, {{ profile.city }} -
                          {{ profile.state }}
                        </div>

                        <div class="mb-2">
                          <strong>
                            CEP:
                          </strong>
                          {{ profile.zipcode }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col md="6">
            <v-card>
              <v-card-title>
                Dias da semana
              </v-card-title>

              <v-card-text>
                <v-list v-for="(weekday, k) in profile.weekDays" :key="k + 'w'">
                  <v-list-item>
                    {{
                      `${weekday.name}: ${weekday.startTime} às ${weekday.endTime}`
                    }}
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="6">
            <v-card>
              <v-card-title>
                Dias Fechados
              </v-card-title>

              <v-card-text>
                <v-list v-for="(dayOff, k) in profile.dayOffs" :key="k + 'd'">
                  <v-list-item>
                    {{ dayOff.startDate | formatDate2 }} às
                    {{ dayOff.endDate | formatDate2 }}
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";

import API_URL from "@/common/config";

import {
  PROFILE_GET,
  // PROFILE_DAYS_OFF_GET,
  PROFILE_LOGO_GET
} from "@/store/actions/profile.type";
import { PROFILE_RESET_STATE, SET_LOGO } from "@/store/mutations/profile.type";
import { ProfileService } from "@/common/api/profile.service";
import { CHANGE_PROGRESS } from "@/store/mutations/mutations.type";

export default {
  name: "Profile",

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.commit(PROFILE_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    await store.commit(PROFILE_RESET_STATE);
    await store.dispatch(PROFILE_GET);
    // await store.dispatch(PROFILE_DAYS_OFF_GET);

    return next();
  },
  data: () => ({
    active: false,
    apiUrl: API_URL,
    updateLogo: null
  }),
  methods: {
    async previewImage(img) {
      if (img.target.files[0]) {
        store.commit(CHANGE_PROGRESS, true);
        await ProfileService.save_logo(img.target.files[0])
          .then(() => {
            store.commit(CHANGE_PROGRESS, false);

            this.snackbar.snackbar = true;
            this.snackbar.color = "success";
            this.snackbar.text = "Salvo com sucesso";
          })
          .catch(({ response }) => {
            store.commit(CHANGE_PROGRESS, false);

            this.snackbar.snackbar = true;
            this.snackbar.color = "error";
            this.snackbar.text = "Erro ao salvar Beauty Maker.";
            // console.log(response.data);
            if (typeof response.data.message !== "undefined") {
              this.snackbar.text =
                "Erro ao salvar Beauty Maker: " + response.data.message;
            }
          });
        store.commit(SET_LOGO, URL.createObjectURL(img.target.files[0]));
      }
    }
  },
  mounted() {
    store.dispatch(PROFILE_LOGO_GET);
  },
  computed: {
    ...mapGetters(["profile", "logo", "snackbar"])
  }
};
</script>

<style scoped>
.gridSys {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-2-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
